import styled from "styled-components"
import Box from "shared/components/Box"
import { bodyFont } from "mill/utils/settings"
import { inputTextColor } from "mill/utils/colors"
import { md } from "mill/utils/breakpoints"

export const Container = styled(Box)`
  border-bottom: 1px solid rgb(236, 236, 236);

  transition: 0.1s padding ease-out;
  &:hover {
    padding-left: 0.5rem;
    transition: 0.1s padding ease-in;
    i {
      transition: 0.1s color ease-in;
      color: ${props => props.theme.colors.secondary};
    }

    > div > div > div > i {
      color: white;
    }
  }
`

export const Title = styled.h3`
  font-size: 1.6rem;
  font-weight: normal;
  font-family: ${bodyFont};
  color: ${inputTextColor};
  margin: 0;
  display: flex;
  justify-content: start;
  align-items: center;
  flex: 1;
  line-height: 2rem;
  @media ${md} {
    padding: 0 2rem;
  }
`

export const Icon = styled.div`
  color: ${props => props.theme.colors.primary};
  transition: 0.1s color ease-out;
  padding-right: 2rem;
  letter-spacing: 1px;
  font-size: 1.2rem;
  margin-top: 1rem;

  span {
    margin-right: 0.5rem;
    font-size: 1.4rem;
  }

  @media ${md} {
    font-size: 2.4rem;
    align-items: center;
    display: flex;
    margin: 0;
    span {
      display: none;
    }
  }
`

export const Image = styled.div`
  background-position: center center;
  background-size: cover;
  background-image: url(${props => props.backgroundImage});
  position: relative;

  @media ${md} {
    min-width: 12rem;
    max-width: 12rem;
    margin: 0;
  }

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 33%;
  }

  > div {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  i {
    font-size: 2.8rem;
    color: white;
    cursor: pointer;
  }

  &:hover i {
    opacity: 0.8;
    color: white;
  }
`
