import React, { useState } from "react"
import Link from "shared/components/Link"
import { List, Button, Subnav } from "./styles"

const FloatingActionButton = ({ toggleModal }) => {
  const [subnavOpen, setSubnavOpen] = useState(false)

  const toggleSubnav = () => {
    setSubnavOpen(!subnavOpen)
  }

  return (
    <List>
      <li>
        <Button data-qa="floating-action" onClick={toggleSubnav}>
          ?
        </Button>
        <Subnav isOpen={subnavOpen}>
          <li>
            <a data-qa="platform-feedback" href="#" onClick={toggleModal}>
              Give feedback
            </a>
          </li>
          <li>
            <Link to="/tour">Take tour</Link>
          </li>
          <li>
            <a href={window.SUPPORT_URL} target={"_blank"} rel="noreferrer">
              Support
            </a>
          </li>
        </Subnav>
      </li>
    </List>
  )
}

export default FloatingActionButton
