import React, { useState, useContext, Fragment } from "react"
import { useParams } from "react-router-dom"
import Link from "shared/components/Link"
import SidebarList from "mill/components/LearningLibrary/SidebarList"
import { ParentItem, ChildItem, ArticleChildren, ArrowToggle } from "./styles"
import FeatureFlagsContext from "mill/utils/FeatureFlagsContext"

const ParentArticle = ({ article }) => {
  const { featureFlags } = useContext(FeatureFlagsContext)
  const [open, setOpen] = useState(
    !featureFlags.collapseLearningLibraryHeadings
  )
  const chevronDirection = open ? "up" : "down"
  const hasChildren = article.children.length > 0

  return (
    <Fragment key={article.id}>
      <li>
        <ParentItem active={open}>
          <Link to={`/learning-library/articles/${article.id}`}>
            {article.title}{" "}
          </Link>
          {hasChildren && (
            <ArrowToggle
              onClick={() => setOpen(!open)}
              className={`fa fa-chevron-${chevronDirection}`}
            />
          )}
        </ParentItem>
      </li>
      {open &&
        hasChildren &&
        article.children.map(child => (
          <ChildArticle key={child.id} article={child} />
        ))}
    </Fragment>
  )
}

const ChildArticle = ({ article }) => {
  const [open, setOpen] = useState(false)
  const { articleId } = useParams()

  const chevronDirection = open ? "up" : "down"
  const hasChildren = article.children.length > 0
  const marginMultiplier = article.depth - 1

  return (
    <li>
      <ChildItem
        leftMargin={marginMultiplier}
        isCurrentArticle={articleId === article.id}
        isNested={article.depth >= 3}>
        <Link to={`/learning-library/articles/${article.id}`}>
          {article.title}{" "}
        </Link>
        {hasChildren && (
          <ArrowToggle
            onClick={() => setOpen(!open)}
            className={`fa fa-chevron-${chevronDirection}`}
          />
        )}
      </ChildItem>
      {open && hasChildren && (
        <ArticleChildren>
          {article.children.map(child => (
            <ChildArticle key={child.id} article={child} />
          ))}
        </ArticleChildren>
      )}
    </li>
  )
}

const ArticlesNavigator = ({ articles }) => {
  return (
    <SidebarList title="Library Articles" style={{ width: "90%" }}>
      <ul>
        {articles.map(article => (
          <ParentArticle key={article.id} article={article} />
        ))}
      </ul>
    </SidebarList>
  )
}

export default ArticlesNavigator
