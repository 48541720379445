import React from "react"
import { number, string, bool } from "prop-types"
import { NoteBox } from "./styles"

const NpsRatingItem = props => {
  let className = ""

  if (props.selected) {
    className = "selected"
  }
  return (
    <NoteBox
      data-qa={`nps-${props.note}`}
      className={className}
      style={{ width: props.width }}
      onClick={props.click}>
      <div>
        <a href="#">
          <span>{props.note}</span>
        </a>
      </div>
    </NoteBox>
  )
}

NpsRatingItem.propTypes = {
  note: number.isRequired,
  width: string.isRequired,
  selected: bool.isRequired
}

export default NpsRatingItem
