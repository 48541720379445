import styled from "styled-components"
import { md } from "mill/utils/breakpoints"
import { libraryBorder, libraryGray, libraryTitle } from "mill/utils/colors"
import { darken } from "polished"
import zIndex from "shared/utils/zIndexLevels"

export const Wrapper = styled.div`
  max-width: 140rem;
  margin: 0 auto;
  position: relative;
  padding: 0 1.5rem;
  @media ${md} {
    display: flex;
  }
`

export const Icon = styled.div`
  position: absolute;
  z-index: ${zIndex.menu + 1};
  left: 2.5rem;
  top: 0.4rem;
  font-size: 2.2rem;
  color: ${props => props.theme.colors.primary};
  pointer-events: none;
`

export const SectionTitle = styled.h3`
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: normal;
  color: ${darken(0.2, "#8e9091")};
  font-weight: bold;
  margin: 0.5rem 0 1rem 0;
  padding: 0;
`

export const Container = styled.div`
  padding: 1rem 0;
  background-color: ${libraryGray};

  .react-autosuggest__input {
    background: white;
    border-radius: 3px;
    border: 1px solid ${libraryBorder};
    height: 4rem;
    line-height: 4rem;
    width: 100%;
    padding: 0 2rem 0 4rem;
    font-size: 1.6rem;

    @media ${md} {
      max-width: 300px;
      min-width: 300px;
    }
    color: ${libraryTitle};
  }

  .react-autosuggest__container {
    position: relative;
    z-index: ${zIndex.menu};
    flex: 1;
    @media ${md} {
      flex: none;
    }
  }

  .react-autosuggest__suggestions-container {
    position: absolute;
    top: 4.3rem;
    left: 0.1rem;
    right: 0.1rem;
    background: rgb(253, 253, 253);
  }

  .react-autosuggest__suggestions-container--open {
    border-radius: 0 3px 3px 0;
    border: 1px solid ${libraryBorder};
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
    padding: 1rem 2rem;
    max-height: 300px;
    overflow-y: scroll;
    width: 100%;
  }

  .react-autosuggest__suggestions-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`
