import React from "react"
import styled from "styled-components"
import { md } from "mill/utils/breakpoints"

const Background = styled.div`
  background: #e5e5e5;
  min-height: 100vh;
  width: 100%;
`

const NotFound = styled.div`
  text-align: center;
  overflow: hidden;
  background-color: white;
  flex: 1;
`

const ErrorImage = styled.div`
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  background-color: white;
  background-image: url("https://yarno.imgix.net/static/errorImage.png?auto=compress,format");
  position: relative;
  width: 100%;
  margin-top: 30rem;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: 40%;
  }
`

const Content = styled.div`
  margin-top: -28rem;
  margin-bottom: 30rem;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 1.5rem;

  @media ${md} {
    margin-bottom: 00rem;
    margin-top: -25rem;
  }
`

const ErrorWrap = ({ children }) => {
  return (
    <Background>
      <NotFound>
        <ErrorImage>
          <Content>{children}</Content>
        </ErrorImage>
      </NotFound>
    </Background>
  )
}

export default ErrorWrap
