import React from "react"
import styled from "styled-components"
import { md } from "mill/utils/breakpoints"
import ErrorWrap from "mill/components/ErrorWrap"

const Title = styled.h2`
  color: ${props => props.theme.colors.secondary};
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: "Raleway";
  font-weight: 600;
  line-height: 1.1em;
  margin: 0;
  padding: 0 1.5rem;
  margin-bottom: 1.5rem;
  @media ${md} {
    font-size: 3rem;
  }
`

const Para = styled.p`
  font-size: 1.5rem;
  max-width: 60rem;
  margin: 0 auto 1rem auto;
  padding: 0 1.5rem;

  span {
    white-space: nowrap;
  }
`

const List = styled.ul`
  font-size: 1.5rem;
  max-width: 30rem;
  padding: 0 1.5rem;
  margin: 0 auto;
  list-style: none;

  a {
    color: ${props => props.theme.colors.primary};
  }

  @media ${md} {
    max-width: 42rem;
  }
`

const ErrorPage = () => {
  return (
    <ErrorWrap>
      <Title>It looks like something has gone wrong!</Title>
      <Para>
        There’s no need to worry – our finest engineers have been dispatched to
        investigate what might have happened. In the meantime, please try these
        troubleshooting steps to see if we can get you{" "}
        <span>{window.PLATFORM_NAME}-ing</span> again:
      </Para>
      <List>
        <li>&bull; Refreshing the page</li>
        <li>
          &bull;{" "}
          <a href="https://learn.yarno.com.au/login">
            Reopening {window.PLATFORM_NAME}'s login page
          </a>{" "}
          and try logging in again
        </li>
        <li>&bull; Open {window.PLATFORM_NAME} in a different browser</li>
      </List>
    </ErrorWrap>
  )
}

export default ErrorPage
