import React from "react"
import { Detector } from "react-detect-offline"
import styled from "styled-components"
import { sm, md } from "mill/utils/breakpoints"

const Title = styled.h1`
  margin: 0;
  padding: 0;
  font-size: 1rem;
  color: white;
  text-transform: uppercase;
  text-align: center;
  height: 5rem;
  line-height: 5rem;
  font-weight: 600;
  letter-spacing: 1px;
  font-family: "Raleway", sans-serif;

  @media ${sm} {
    font-size: 1.2rem;
  }

  @media ${md} {
    font-size: 1.4rem;
    letter-spacing: 2px;
  }
`

const PageTitle = ({ title }) => {
  return (
    <Detector
      polling={{
        url: window.POLL_URL,
        interval: 10000,
        timeout: 5000
      }}
      render={({ online }) => (
        <Title id="qa--page-title">
          {online ? title : "You are offline"}
        </Title>
      )}
    />
  )
}

export default PageTitle
