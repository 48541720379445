import React from "react"
import Link from "shared/components/Link"
import Box from "shared/components/Box"
import styled from "styled-components"
import FloatingActionButton from "mill/components/FloatingActionButton"
import PageTitle from "mill/components/PageTitle"

import { md } from "mill/utils/breakpoints"
import zIndex from "shared/utils/zIndexLevels"

const Nav = styled.nav`
  background: ${props => props.theme.colors.primary};
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: ${zIndex.menu};
  width: 100%;
`

const Logo = styled(Link)`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 0 0 1.5rem;
  margin: 0;

  img {
    display: inline-block;
    height: 20px;
    margin: 16px 0 13px 0;
  }

  @media ${md} {
    padding-left: 2rem;
  }
`

const WelcomeContainer = styled.div`
  position: absolute;
  right: 6rem;
  top: 0;
  display: flex;
  z-index: ${zIndex.menu};
`
const Welcome = styled.p`
  display: none;

  @media ${md} {
    font-size: 1.5rem;
    display: block;
    color: white;
    margin: 0;
    padding: 0;
    line-height: 5rem;
  }
`

const Header = props => {
  const logoUrl = window.THEME.reverseLogoUrl + "?auto=format&h=20"

  return (
    <Nav>
      <Box position="relative">
        <Logo onClick={props.closeMenu} to="/">
          <img
            src={logoUrl}
            srcSet={`${logoUrl}&dpr=1 1x, ${logoUrl}&dpr=2 2x, ${logoUrl}&dpr=3 3x`}
            alt={window.PLATFORM_NAME}
          />
        </Logo>
        <PageTitle title={props.pageTitle} />
        {props.currentUser.nickname && (
          <WelcomeContainer push={true}>
            <Welcome>Welcome, {props.currentUser.nickname}</Welcome>
            <FloatingActionButton toggleModal={props.toggleModal} />
          </WelcomeContainer>
        )}
      </Box>
    </Nav>
  )
}

export default Header
