import styled from "styled-components"

export const Container = styled.div`
  text-align: center;
  padding-top: 3rem;
  margin: 0 3rem;
  width: 100%;

  h1 {
    color: ${props => props.theme.colors.primary};
    text-transform: initial;
    margin-top: 3rem;
    margin-bottom: 2rem;
    font-size: 4rem;
  }

  h2 {
    color: ${props => props.theme.colors.primary};
    clear: both;
    padding-top: 1rem;
    margin-bottom: 4rem;
  }

  button {
    margin-top: 1rem;
  }

  textarea {
    padding: 1.5rem;
  }
`
export const Clear = styled.div`
  clear: both;
  height: 2rem;
`

export const SuccessImg = styled.img`
  clear: both;
  max-width: 100%;
`

export const Form = styled.form`
  margin-bottom: 8rem;
  max-width: 600px;
  margin: 0 auto;

  input {
    width: 100%;
  }

  textarea {
    width: 100%;
  }

  small {
    border-radius: 3px;
    opacity: 0.7;
  }

  p {
    text-align: left;
    background-color: #ed6364;
    color: white;
    font-size: 1.4rem;
    padding: 0.5rem 1rem;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }

  fieldset label {
    color: #535353;
  }
`

export const RatingPicker = styled.div`
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  small {
    font-style: italic;
  }
`

export const Rating = styled.span`
  font-size: 1.8rem;
  color: orange;
`
