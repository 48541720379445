import React, { useContext } from "react"
import { useLazyQuery } from "@apollo/client"
import { useParams } from "react-router-dom"
import FeatureFlagsContext from "mill/utils/FeatureFlagsContext"
import styled from "styled-components"
import { md } from "mill/utils/breakpoints"
import Link from "shared/components/Link"

import FETCH_ARTICLE_BREADCRUMBS from "mill/graphql/FetchArticleBreadcrumbs"
import FETCH_TOPIC from "mill/graphql/FetchTopic"
import FETCH_QUESTION from "mill/graphql/FetchQuestion"

const Nav = styled.nav`
  display: none;
  max-width: 90rem;

  ul {
    list-style: none;
    margin: 0;

    padding: 3.2rem 1.5rem 0 4rem;
    font-size: 1.1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    a {
      color: ${props => props.theme.colors.primary};
      transition: color 0.2s ease-out;
      &:hover {
        color: ${props => props.theme.colors.secondary};
        transition: color 0.2s ease-in;
      }
    }
    li {
      display: inline-block;
    }
  }

  @media ${md} {
    display: block;
  }
`

const Separator = styled.span`
  display: inline-block;
  margin: 0 1rem;
  color: #aab0b3;
`

const Breadcrumb = ({ crumb, isLast }) => {
  return (
    <li>
      {isLast ? (
        crumb.title
      ) : (
        <React.Fragment>
          <Link to={crumb.id}>{crumb.title}</Link>
          <Separator>
            <i className="fa fa-chevron-right" />
          </Separator>
        </React.Fragment>
      )}
    </li>
  )
}

const ArticleBreadcrumbs = ({ articleId }) => {
  const [fetchArticleBreadcrumbs, { data, called, variables }] = useLazyQuery(
    FETCH_ARTICLE_BREADCRUMBS
  )

  if (articleId && (!called || variables.articleId !== articleId)) {
    fetchArticleBreadcrumbs({
      variables: { articleId: articleId }
    })
  }

  if (data && data.article) {
    return (
      <React.Fragment>
        {data.article.breadcrumbs.map((crumb, index) => (
          <Breadcrumb
            key={crumb.id}
            crumb={{
              id: `/learning-library/articles/${crumb.id}`,
              title: crumb.title
            }}
            isLast={index === data.article.breadcrumbs.length}
          />
        ))}
        <Breadcrumb
          crumb={{
            title: data.article.title
          }}
          isLast={true}
        />
      </React.Fragment>
    )
  }

  return null
}

const TopicsBreadcrumbs = ({ topicId, questionId }) => {
  const [
    fetchTopic,
    { data: topicData, called: topicCalled, variables: topicVariables }
  ] = useLazyQuery(FETCH_TOPIC)

  const [
    fetchQuestion,
    { data: questionData, called: questionCalled, variables: questionVariables }
  ] = useLazyQuery(FETCH_QUESTION)

  if (topicId && (!topicCalled || topicId !== topicVariables.id)) {
    fetchTopic({
      variables: { id: topicId }
    })
  }

  if (questionId && (!questionCalled || questionId !== questionVariables.id)) {
    fetchQuestion({
      variables: { id: questionId }
    })
  }

  return (
    <React.Fragment>
      {(topicId || questionId) && (
        <Breadcrumb
          crumb={{
            id: "/learning-library/topics",
            title: "Topics"
          }}
        />
      )}
      {topicData && topicData.topic && (
        <Breadcrumb
          crumb={{
            id: `/learning-library/topics/${topicData.topic.id}`,
            title: topicData.topic.name
          }}
          isLast={!questionId}
        />
      )}
      {questionData && questionData.question && (
        <Breadcrumb
          crumb={{
            id: `#`, // this should always be the last link so no path required
            title: questionData.question.question
          }}
          isLast={true}
        />
      )}
    </React.Fragment>
  )
}

const Breadcrumbs = () => {
  const { articleId, topicId, questionId } = useParams()
  const { featureFlags } = useContext(FeatureFlagsContext)

  return (
    <Nav data-qa="breadcrumbs">
      <ul>
        {featureFlags.learningLibraryEnabled && (
          <Breadcrumb
            crumb={{
              id: "/learning-library",
              title: "Learning library"
            }}
            isLast={!articleId && !topicId && !questionId}
          />
        )}
        {articleId ? (
          <ArticleBreadcrumbs articleId={articleId} />
        ) : (
          <TopicsBreadcrumbs topicId={topicId} questionId={questionId} />
        )}
      </ul>
    </Nav>
  )
}

export default Breadcrumbs
