import styled from "styled-components"
import Link from "shared/components/Link"
import { darken } from "polished"
import colors from "shared/utils/colors"
import { md, lg } from "mill/utils/breakpoints"

export const QuestionListings = styled.div`
  margin-bottom: 3rem;
  scroll-behavior: smooth;
  width: 100%;

  img {
    max-width: 100%;
  }

  table {
    margin-top: 2rem;
    width: 100%;
    border-collapse: collapse;
  }

  table,
  th,
  td {
    border: 1px solid ${colors.grayWhisper};
  }
  td {
    padding: 0 1rem;
  }

  td p {
    margin: 1rem 0;
  }

  @media ${md} {
    padding: 0 1.5rem 0 4rem;
    max-width: 80rem;
  }

  @media ${lg} {
    max-width: 90rem;
  }
`

export const Heading = styled.h1`
  font-family: "Raleway", sans-serif;
  font-size: 2.6rem;
  line-height: 40px;
  color: #4b5252;
  letter-spacing: 1.25px;
  font-weight: 600;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgb(236, 236, 236);
  margin-top: 1rem;
  margin-bottom: 3.2rem;
  @media ${md} {
    font-size: 4rem;
    margin-top: 4rem;
    line-height: 50px;
    padding-bottom: 3.2rem;
  }
`

export const TopHeading = styled.h1`
  font-family: "Raleway", sans-serif;
  font-size: 2.6rem;
  line-height: 40px;
  color: #4b5252;
  letter-spacing: 1.25px;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1rem;
  @media ${md} {
    font-size: 4rem;
    margin-top: 4rem;
    line-height: 50px;
  }
`

export const ArticleDate = styled.div`
  color: #adadad;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgb(236, 236, 236);
`

export const PillLink = styled.div`
  display: inline-block;
  margin-right: 0.4rem;
  margin-top: 0.4rem;
  a {
    display: inline-block;
    background: ${darken(0.02, colors.grayAliceBlue)};
    color: ${darken(0.4, colors.graySolitude)};
    padding: 0.3rem 0.8rem;
    border-radius: 3px;
    font-size: 1.1rem;
    letter-spacing: 1px;
    text-transform: uppercase;
    font-weight: 600;

    &:hover {
      background: ${darken(0.07, colors.grayAliceBlue)};
      color: ${darken(0.6, colors.graySolitude)};
    }
    ${props =>
      props.active &&
      `

      background: ${darken(0.07, colors.grayAliceBlue)};
      color: ${darken(0.6, colors.graySolitude)};
`};
  }
`

export const ResourceLinks = styled.div`
  background-color: #f1f1f1;
  padding: 1rem 2rem;
  font-size: 2rem;
  border: 1px solid rgb(216, 216, 216);

  h3 {
    text-transform: uppercase;
    letter-spacing: 1.5px;
    font-family: Raleway;
    font-weight: 600;
    color: ${colors.grayAluminium};
    margin: 0;
    padding-bottom: 1rem;
    font-size: 1.6rem;
    border-bottom: 1px solid rgb(216, 216, 216);
  }
`

export const Image = styled.div`
  max-width: 100%;
  margin-top: 2.5rem;
  img {
    max-width: 100%;
  }
`

export const ExplanationHeader = styled.h3`
  display: none;

  @media ${md} {
    display: block;
    text-transform: uppercase;
    color: ${props => props.theme.colors.primary};
    font-family: "Raleway";
    font-weight: 600;
    letter-spacing: 2px;
    font-size: 1.8rem;
  }
`

export const ArticleWrapper = styled.div`
  display: flex;
  scroll-behaviour: smooth;
  max-width: 122rem;
  position: relative;
`

export const SideNav = styled.div`
  display: none;
  padding-top: 6rem;
  margin-left: 2rem;
  min-width: 18rem;
  width: 26rem;

  @media ${lg} {
    display: inline-block;
    position: sticky;
    align-self: start;
    top: 0;
  }
`

export const EditArticleButton = styled(Link)`
  font-size: 1.8rem;
  font-weight: bold;
  border-left: 1px solid #ececec;
  padding: 1rem 2rem;
  color: #333333 !important;
  text-decoration: none;

  i {
    color: ${props => props.theme.colors.primary};
    padding-right: 2rem;
  }
`

export const Contents = styled.div`
  padding: 1rem 0;
  margin-top: 8rem;
  opacity: 0;
  transition: 0.3s all linear;
  -webkit-transition: 0.3s all linear;

  ${props =>
    props.fixed === "true" &&
    `
    position: fixed;
    top: 0;
    min-width: 26rem;
  `}

  ${props =>
    props.showContents &&
    `
    opacity: 1;
  `}

  h6 {
    padding: 1rem 0 1rem 2rem;
    border-left: 1px solid #ececec;
  }

  h6 {
    font-size: 1.8rem;
    color: #c8cfd2;
    text-transform: uppercase;
    margin: 0;
  }

  div,
  div > a {
    font-size: 1.4rem;
    color: #adadad;
  }
`

export const ContentRow = styled.div`
  padding: 1rem 0 1rem ${props => props.paddingLeft}rem;
  border-left: 1px solid #ececec;
  transition: 0.3s all ease;
  -webkit-transition: 0.3s all ease;
  &:hover {
    cursor: pointer;
  }

  a {
    font-size: 1.4rem;
    color: #adadad;
  }
  ${props =>
    props.isActive === "true" &&
    `
    background-color: #F7F8F9;
    border-left: 2px solid ${props.theme.colors.primary};
    transition: 0.3s all ease;
    -webkit-transition: 0.3s all ease;
    color: ${props.theme.colors.primary};
  `}
`
