import React from "react"
import Box from "shared/components/Box"
import Link from "shared/components/Link"
import { Container, Image, Title, Icon } from "./styles"

export const ArticleResult = ({ article }) => {
  return (
    <Link
      data-qa={`article-${article.id}`}
      to={`/learning-library/articles/${article.id}`}>
      <Container
        paddingTop="medium"
        paddingBottom="medium"
        display={{ desktop: "flex" }}
        justifyContent={{ desktop: "space-between" }}>
        <Title>{article.title}</Title>
        <Icon>
          <span>View article</span>
          <i className="fa fa-chevron-right" />
        </Icon>
      </Container>
    </Link>
  )
}
export const QuestionResult = ({ topicId, question }) => {
  const imageUrl = `${question.mediaThumbnailUrl}?auto=format&q=90&w=120&h=40`

  return (
    <Link
      data-qa={`question-${question.id}`}
      to={`/learning-library/previous-questions/${topicId}/${question.id}`}>
      <Container
        paddingTop="medium"
        paddingBottom="medium"
        display={{ desktop: "flex" }}
        justifyContent={{ desktop: "space-between" }}>
        <Box marginBottom={{ mobile: "small", desktop: "none" }}>
          <Image backgroundImage={`${imageUrl}?auto=format&q=90&w=120&h=40`}>
            {question.hasVideoBanner && (
              <div>
                <i className={"fa fa-play-circle"} />
              </div>
            )}
          </Image>
        </Box>
        <Title>{question.question}</Title>

        <Icon>
          <span>View question</span>
          <i className="fa fa-chevron-right" />
        </Icon>
      </Container>
    </Link>
  )
}

export default QuestionResult
