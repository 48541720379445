import styled from "styled-components"

export const Subnav = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: ${props => (props.isOpen ? "block" : "hidden")};
  background-color: ${props => props.theme.colors.primary};
  color: white;
  height: 0;
  transition: all 0.2s ease-out;
  position: absolute;
  top: 5rem;
  right: 0;
  width: 17rem;
  overflow: hidden;
  margin: 0;
  padding: 0;
  list-style: none;

  li,
  a {
    display: block;
  }
  a {
    color: white;
    display: block;
    border-top: ${props => props.theme.colors.primaryHover};
    font-size: 1.4rem;
    padding: 1rem 2.5rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: normal;
    &:hover {
      background-color: ${props => props.theme.colors.primaryHover};
    }
  }
`

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: right;

  > li {
    position: relative;
    display: block;
    height: 5rem;

    &:hover {
      ul {
        display: block;
        height: auto;
        transition: all 0.2s ease-in;
      }
    }
  }
`

export const Button = styled.span`
  line-height: 5rem;
  height: 5rem;
  display: block;
  color: white;
  text-align: center;
  font-size: 2.5rem;
  text-decoration: none;
  font-weight: 300;
  transition: all 0.2s ease-out;
  margin-top: -2px;
  min-width: 5rem;

  &:hover {
    cursor: pointer;
    opacity: 0.4;
    transition: all 0.2s ease-in;
  }
`
