import styled from "styled-components"
import { sm, md } from "mill/utils/breakpoints"
import zIndex from "shared/utils/zIndexLevels"

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const ToggleBar = styled.div`
  background: #f7f8f9;
  min-height: 3.5rem;
  width: 100%;

  i {
    font-size: 2rem;
    padding: 1rem 1.5rem;
    color: ${props => props.theme.colors.primary};
    transition: transform 0.5s;
    -webkit-transition: transform 0.5s;
    ${props =>
      props.isOpen &&
      `
      transform: translate(calc(100vw - 5rem)) rotate(180deg);
      transition: transform 0.5s;
      -webkit-transition: transform 0.5s;
    `}

    @media ${sm} {
      ${props =>
        props.isOpen &&
        `
        transform: translate(33rem) rotate(180deg);
      `}
    }
  }

  @media ${md} {
    display: none;
  }
`

export const MenuContainer = styled.div`
  flex: 1;
  display: block;
  @media ${md} {
    padding-right: 2rem;
    padding-left: 0.5rem;
  }
`

export const Menu = styled.div`
  max-width: 85%;
  padding-top: 1rem;
  padding-bottom: 2rem;
  position: fixed;
  top: 5rem;
  left: -85%;
  bottom: 0;
  right: 0;
  background: #f7f8f9;
  min-height: calc(100vh - 5rem);
  max-height: calc(100vh - 5rem);
  overflow: auto;
  display: inline-block;
  transition: transform 0.5s;
  -webkit-transition: transform 0.5s;
  z-index: ${zIndex.menu};
  ${props =>
    props.isOpen &&
    `
    transform: translateX(calc(85vw));
    transition: transform 0.5s;
    -webkit-transition: transform 0.5s;
  `}

  @media ${sm} {
    max-width: 33rem;
    min-width: 33rem;
    left: -33rem;
    ${props =>
      props.isOpen &&
      `
      transform: translateX(33rem);
      transition: transform 0.5s;
      -webkit-transition: transform 0.5s;
    `}
  }

  @media ${md} {
    left: 0;
    display: block;
    transform: none;
  }
`

export const MenuContents = styled.div`
  display: block;
  margin: 0.8rem 1rem 0 2rem;

  @media ${sm} {
    margin: 0.8rem 2rem;
  }

  @media ${md} {
    display: flex;
    flex-direction: column;
  }
`

export const ContentWrapper = styled.div`
  padding: 0 1rem;
  width: 100%;
  scroll-behavior: smooth;

  @media ${sm} {
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
  }

  @media ${md} {
    margin-left: 33rem;
  }
`
