import React from "react"
import styled from "styled-components"

const Result = styled.div`
  display: flex;

  border-top: 1px solid rgb(236, 236, 236);
  padding: 1rem 0;
  transition: padding 0.1s ease-out;

  &:hover {
    cursor: pointer;
    transition: padding 0.1s ease-in;
    padding-left: 1rem;
  }
`

const Label = styled.div`
  font-size: 1.4rem;
  line-height: 1.8rem;
  flex: 1;
  display: flex;

  i {
    margin-right: 1rem;
    color: ${props => props.theme.colors.primary};
    font-size: 0.8em;
    margin-top: 0.4rem;
  }
`

const SearchResult = props => {
  return (
    <Result>
      <Label>
        <i className="fa fa-chevron-right" /> {props.suggestion.label}
      </Label>
    </Result>
  )
}

export default SearchResult
