import styled from "styled-components"
import zIndex from "shared/utils/zIndexLevels"

const menuWidth = "256px"

export const Banner = styled.div`
  height: 7rem;
  width: 100vw;
  background-color: #ff6b00;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: fixed;
  padding: 0 1rem;
  top: 5rem;
  left: 0;
  z-index: ${zIndex.obnoxious};

  a {
    font-size: 1.4rem;
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (min-width: 52em) {
    height: 5rem;
  }
`

export const Wrapper = styled.div`
  overflow: hidden;
  display: flex;
  min-height: 100%;
  flex-direction: column;
  flex: 1;
`

export const ApplicationBar = styled.div`
  height: 5rem;
`

export const ApplicationFrame = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  max-width: 100%;
  min-height: calc(100vh - 5rem);
`

export const Menu = styled.div`
  width: ${menuWidth};
  min-width: ${menuWidth};
  max-width: ${menuWidth};
  background-color: ${(props) =>
    props.isAdmin ? props.theme.colors.admin : props.theme.colors.primary};
  transform: translate3d(0, 0, 0);
  transition: transform 0.2s;
  ${(props) =>
    props.isOpen &&
    `
    transform: translate3d(-${menuWidth},0,0);
    position: fixed;
    left: auto;
    top: 5rem;
    bottom: 0;
    right: -${menuWidth};
    z-index: ${zIndex.menu};
  `} ul {
    margin: 0;
    padding: 0;
  }

  li {
    list-style: none;
  }

  a {
    color: white;
    text-decoration: none;
    display: block;
    padding: 1rem 1rem 1rem 3rem;
    transition: background 0.2s ease-out;
    border-top: 1px solid
      ${(props) =>
        props.isAdmin
          ? props.theme.colors.adminHover
          : props.theme.colors.primaryHover};
    line-height: 3rem;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 1.4rem;

    &:hover {
      background-color: ${(props) =>
        props.isAdmin
          ? props.theme.colors.adminHover
          : props.theme.colors.primaryHover};
      transition: background 0.2s ease-in;
    }
  }
`

export const Content = styled.div`
  flex: 1 0 auto;
  width: 100%;
  min-width: 100%;
  flex-direction: row;
  display: flex;
  background: ${props => props.backgroundColor || "white"};
  ${props => props.withTopMargin && `margin-top: 7rem;`}

  @media screen and (min-width: 52em) {
    ${props => props.withTopMargin && `margin-top: 5rem;`}
  }
`
