import styled from "styled-components"

export const ParentItem = styled.div`
  display: flex;
  margin-top: 2rem;
  padding: 1rem 0rem;

  a {
    font-size: 1.6rem;
    letter-spacing: 0.5px;
    display: block;
    color: #aab0b3;
    font-weight: 600;
    line-height: 1.8rem;
    transition: 0.1s color ease-out;
    width: 100%;
    text-transform: uppercase;
    padding: 0.5rem 0;

    &:hover {
      transition: 0.1s color ease-in;
    }
  }
`

export const ChildItem = styled(ParentItem)`
  margin-top: 0;
  padding: 0.4rem 0;
  align-items: center;

  a {
    color: #4b5252;
    text-transform: none;
    font-weight: 500;
  }

  ${props => props.leftMargin && `
    margin-left: ${props.leftMargin}px;
  `}
  ${props => props.isCurrentArticle && props.isNested && `
    a {
      background-color: white;
      color: ${props.theme.colors.primary};
    }
  `}
`

export const ArticleChildren = styled.ul`
  li div a {
    padding: 1rem 0rem;
  }
`

export const ArrowToggle = styled.i`
  position: relative;
  color: ${props => props.theme.colors.primary};
  align-items: flex-start;
  transition: 0.1s color ease-out;
  padding: 0.5rem 0 0.5rem 1rem;

  &:hover {
    transition: 0.1s color ease-in;
    color: ${props => props.theme.colors.secondary};
    cursor: pointer;
  }
`
