import React from "react"
import styled from "styled-components"
import { headingFont } from "mill/utils/settings"
import { libraryGray } from "mill/utils/colors"

const Heading = styled.h3`
  font-family: ${headingFont};
  font-size: 1.8rem;
  color: ${props => props.theme.colors.primary};
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 0;
`

const Nav = styled.nav`
  ul {
    margin: 0;
    margin-bottom: 2rem;
    padding: 0;
  }

  li a {
    padding-right: 3.2rem;
  }

  & > ul {
    margin-top: 1.2rem;
  }

  & > ul > li > div {
    border-bottom: 2px solid ${libraryGray};
  }

  & > ul > li:last-of-type > div {
    border-bottom: none;
  }

  & > ul > li > ul {
    border-left: 2px solid ${libraryGray};
    margin-bottom: 2rem;
  }

  ul ul div {
    margin-top: 0;
    background: #f7f8f9;
    padding: 0;
  }

  ul ul li {
    a {
      padding-left: 1.8rem;
      margin-left: 1rem;
      position: relative;
      line-height: 1.2;
      font-size: 0.9em;
      border-left: 1px solid #c8cfd2;
    }
  }

  ul ul {
    margin: 0;
  }

  ul ul ul {
    a {
      margin-left: 3rem;
    }
  }

  ul ul ul ul {
    a {
      margin-left: 4rem;
    }
  }

  ul ul ul ul ul {
    a {
      margin-left: 5rem;
    }
  }

  ul ul ul ul ul ul {
    a {
      margin-left: 6rem;
    }
  }
`

const SidebarList = props => {
  return (
    <Nav style={props.style}>
      <Heading>{props.title}</Heading>
      {props.children}
    </Nav>
  )
}

export default SidebarList
